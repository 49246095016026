import './css/global'
import LocomotiveScroll from 'locomotive-scroll'
export default class Main {
    constructor() {
        this.currentFeatures = 'track';
        this.init()
    }

     scrolling(obj) {
      console.log(this.kv, this.story, this.scroller, obj.scroll.y);
      if ((obj.scroll.y > this.kv) && (this.kv + this.story + this.scroller > obj.scroll.y)) {
        var c = obj.scroll.y - this.kv - this.story;
        var s = this.scroller / 6;
        if (c < s) {
            this.updateFvc('track')
        } else if (c > s && c < s * 3) {
            this.updateFvc('fight')
        } else {
            this.updateFvc("find");
        }
      }
    }

    updateFvc(val) {
        if (this.currentFeatures == val) {
          return;
        }
        this.fvc.classList.remove("track");
        this.fvc.classList.remove("fight");
        this.fvc.classList.remove("find");
        this.currentFeatures = val
        this.fvc.classList.add(val);
    }

    init() {
        this.fvc = document.querySelector(".fvc");
        if(this.fvc) {
            this.onResize();
        }
        if (!(('ontouchstart' in window)
            || (navigator.MaxTouchPoints > 0)
            || (navigator.msMaxTouchPoints > 0))) {
            document.querySelector('body').classList.add('not-touch');
            }
        if(this.fvc) {
            this.initKvOver()
            this.initScroll();
            window.addEventListener('resize', () => {this.onResize()})
        }

        var promise = document.querySelector('video').play();

        if (promise !== undefined) {
            promise.then(_ => {}).catch(error => {
                console.log('not autoplay');
                document.querySelector('.kv-visual-sp').classList.add('active');
            });
        }
    }

    initKvOver() {
        document.querySelectorAll('.kv a').forEach((item) => {
            item.addEventListener('mouseover', this.kvOver, false)
            item.addEventListener('mouseout', this.kvOut, false)
        });
    }

    kvOver() {
        document.querySelector('.kv-monb').classList.add('hov')
    }

    kvOut() {
        document.querySelector('.kv-monb').classList.remove('hov')
    }

    initScroll() {
        if (window.innerWidth > 767 && this.scroll == undefined) {
          this.scroll = new LocomotiveScroll({
            el: document.querySelector("[data-scroll-container]"),
            smooth: true,
            touchMultiplier: 1
          });
          this.scroll.on("scroll", func => {
            this.scrolling(func);
          });
        }
    }

    removeScroll() {
        if (window.innerWidth < 768 && this.scroll) {
            this.scroll.destroy();
            this.scroll = undefined;
        }
    }

    onResize() {
        if (window.innerWidth < 768) {
             // document.querySelector(".fvcc").removeAttribute("data-scroll-sticky");
             // document.querySelector(".fvcc").removeAttribute("data-scroll");

        } else if (window.innerWidth < 981) {
            document.querySelector(".story-ct").removeAttribute("data-scroll-speed");
            document.querySelector(".story-ct").removeAttribute("data-scroll");
            document.querySelectorAll(".promises-list li").forEach((item)=> {
                item.removeAttribute("data-scroll-speed");
                item.removeAttribute("data-scroll");
            });
        }
        this.kv = document.querySelector(".kv").clientHeight;
        this.story = document.querySelector(".story").clientHeight;
        this.scroller = document.querySelector(".features").clientHeight;
        this.initScroll()
        this.removeScroll();
    }
}

new Main();